import { $t } from '@/i18n/index'

export const DataTypeForReconciliation = [
  {
    value: 'TRANSACTION',
    label: $t('report.transfer')
  },
  {
    value: 'LEDGER',
    label: $t('common.accountingLedger')
  },
  {
    value: 'TREASURY_ACCOUNT_BALANCE',
    label: $t('reconciliation.treasuryAccountBalance')
  },
  {
    value: 'ACCOUNT_BALANCE',
    label: $t('report.accountBalance')
  },
  {
    value: 'TRADE',
    label: $t('common.trade')
  },
  {
    value: 'GAIN_LOSS',
    label: $t('project.costBasis.gainOrLoss')
  },
  {
    value: 'BUSINESS_DATA',
    label: $t('report.businessData')
  }
]
